<template>
  <!-- Card Banner mit Category Title -->
  <q-card class="my-card">
    <!-- Add Badge for Wahlfach -->
    <q-card-section class="banner column justify-end items-start q-pa-sm" style="background-color: #98F280"
      >
      <q-badge class="badge">
      <q-btn round color="grey-5" icon="close" style="opacity: 0.8" @click="removeWahlfach(freeElective)" />
    </q-badge>
      <q-badge text-color="grey-7" class="absolute-top-right q-pa-sm" color="transparent" align="top">
        {{$t('studyplan.freeElective')}}
      </q-badge>

      <div :class="fontSize">{{ freeElective.name }}</div>
    </q-card-section>

    <q-separator />
    <!-- Card lower part Info -->
    <q-card-section class="items">
      <!-- Course Info -->
      <div class="row items-end">
        <div class="col-8">
          <div class="row justify-start no-wrap">
            <div :class="itemSize + ' col-3'" style="background-color: #98F280">
              <p class="upper-text">{{freeElective.subject_type}}</p>
              <p class="lower-text"> {{$t('studyplan.subject_type')}} </p>
            </div>
            <div :class="itemSize + ' col-3'" style="background-color: #98F280">
              <p class="upper-text">{{freeElective.ects}}</p>
              <p class="lower-text">ECTS</p>
            </div>
            <!-- SST wird gehided -->
            <div style="display: none" :class="itemSize + ' col-3'">
              <p class="upper-text">SSt</p>
              <p class="lower-text">SSt</p>
            </div>
            <div :class="itemSize + ' col-3'" style="background-color: #98F280">
              <p class="upper-text">{{ freeElective.grade}}</p>
              <p class="lower-text"> {{$t('studyplan.subject_grade')}} </p>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    freeElective: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    return {
      grade: ref(props.freeElective.grade)
    };
  },
  methods: {
    removeWahlfach(freeElective) {
       this.$emit('removeFreeElective', freeElective);
    }
  },
  computed: {
    fontSize() {
      return this.$q.screen.lt.md
        ? "category-title text-h5"
        : "category-title-small text-h6";
    },
    itemSize() {
      return this.$q.platform.is.desktop
        ? "item shadow-1"
        : "item-small shadow-1";
    },
  },

  mounted() { }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}

.my-card {
  width: 100%;
  height: 100%;
  max-height: 220px;
  color: $grey-9;
}

.badge {
  background-color: transparent;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 2;
}

.banner {
  height: 70%;
  transition: all 300ms ease;
}

.category-title {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.category-title-small {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.course-title {
  font-size: min(2.5vw, 16px);
  font-family: Poppins, sans-serif;
}

.items {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
}

.item {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  min-height: 20px;
  max-width: 80px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.item-small {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  min-height: 30px;
  max-width: 50px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.upper-text {
  margin-bottom: 0;
  font-size: min(1.5vw, 15px);
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

.lower-text {
  margin-bottom: 0;
  font-size: min(1vw, 9px);
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

@media only screen and (min-width: 1024px) and (max-width: 1439.99px) {
  .my-card {
    height: 100%;
    width: 100%;
    max-height: 220px;
    min-width: 250px;
  }

  .item {
    background-color: $yellow-2;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 32px;
    min-height: 20px;
    max-width: 50px;
    margin-right: 10px;
  }

  .course-title {
    font-size: 12px;
  }

  .upper-text {
    font-size: min(1.5vw, 12px);
  }
}

@media only screen and (min-width: 0px) and (max-width: 599.99px) {
  .upper-text {
    font-size: min(2.5vw, 12px);
  }

  .lower-text {
    font-size: min(1.7vw, 9px);
  }

  .course-title {
    font-size: 13px;
  }
}
</style>
