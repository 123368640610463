<template>
  <div>
    <!-- Studium Titel -->
    <div class="row margin-lg-div q-mb-md justify-center">
      <div class="col-md-12">
        <div :class="fontSize + ' text-center text-bold'" id="heading-info-h2">
          {{ studyObject.study.name }} <br />
        </div>
      </div>
      <AddStudyButton v-if="showAddButton" @click="clickEvent" />
    </div>
    <!-- 
      If User is not logged in
     -->
    <div class="row margin-lg-div justify-center" v-if="!userStore.loggedIn">
      <div class="col-md-6 row justify-center">
        <q-icon name="error" color="red" size="md" />
        <div class="text-h6 text-center text-bold text-red q-mt-md">
          {{ $t("studyplan.not_logged_in_notification") }}
        </div>
      </div>
    </div>
    <div class="q-mb-xl">
      <Dashboard
        v-if="
          userStore.loggedIn && userStore.getStudy(this.studyObject.study._id)
        "
        :semester_ects="
          userStore.calcDashboardData(this.studyObject.study._id).semester_ects
        "
        :current_subjects="
          userStore.calcDashboardData(this.studyObject.study._id)
            .current_subjects
        "
        :gpa="userStore.calcDashboardData(this.studyObject.study._id).gpa"
        :min_ects="0"
        :max_ects="180"
        :current_ects="
          userStore.calcDashboardData(this.studyObject.study._id).current_ects
        "
        :completed_subjects="
          userStore.calcDashboardData(this.studyObject.study._id)
            .completed_subjects
        "
        :checkGradeInsert="userStore.calcDashboardData(this.studyObject.study._id).checkGradeInsert"
      />
    </div>

    <div class="column items-center container" v-if="userStore.loggedIn">
      <div
        :class="
          [item.show ? 'col-md-12 full-width ' : 'col-md-6'] +
          ' col-xs-12 shadow-1 row justify-center col-6 q-mt-lg q-pa-md rounded-borders item section'
        "
        v-for="(item, i) in statusArray_old"
        :key="i"
        style="width: 50%"
      >
        <div class="row justify-start items-center full-width">
          <div class="col-xs-4">
            <q-toolbar class="text-blue-7" style="backgroundcolor: #f6f8fa">
              <q-toolbar-title class="text-h6 text-bold q-pa-sm">
                {{ this.studyId == "bbe" ? item.bbeTitle : item.title }}
              </q-toolbar-title>
            </q-toolbar>
          </div>
          <div class="col-xs-4">
            <q-toolbar
              class="text-blue-7 text-center"
              style="backgroundcolor: #f6f8fa"
            >
              <q-toolbar-title class="text-body1 q-pa-sm">
                {{ item.array.length }} {{ $t("studyplan.subjects") }}
              </q-toolbar-title>
            </q-toolbar>
          </div>
          <div class="col-xs-4">
            <div class="row justify-end items-end">
              <q-icon
                @click="item.show = !item.show"
                class="rounded-borders individual-bg cursor-pointer"
                :name="item.show ? 'expand_less' : 'expand_more'"
                color="blue-7"
                :size="iconSize"
              ></q-icon>
            </div>
          </div>
        </div>
        <div v-if="item.show" class="row q-col-gutter-x-md full-width">
          <div
            :class="colSize"
            v-for="subject in item.array"
            :key="subject._id"
            style="min-height: 250px; z-index: 5"
          >
            <Subject
              v-if="studyLoaded"
              class="q-mt-md"
              draggable="true"
              :subject="subject"
              :studyObject="studyObject"
              :sbwlss="sbwlss"
              :freeElectivess="freeElectivess"
              :studyId="studyId"
              @update-status="updateStatus"
              @update-grade="updateGrade"
              @show-path="showPath"
              @handle-drag-start="handleDragStart($event, subject)"
              @drag="handleDrag"
              @dragend="handleDragEnd"
            />
          </div>
        </div>
      </div>
      <div
        class="full-width row justify-center"
        v-if="this.studyId !== 'wiso-vwl' && this.studyId !== 'wiso-sz'"
      >
        <SbwlCarousel
          v-if="studyLoaded"
          @update-status-sbwl="updateStatusSbwl"
          @update-sbwl="updateSbwl"
          @remove-sbwl="removeSbwl"
          @update-grade-sbwl="updateGradeSbwl"
          @allSbwlsDone="allSbwlsDone"
          :study_id="studyId"
          :studyObject="studyObject"
          :sbwlss="sbwlss"
          :statusUpdated="statusUpdated"
        />
      </div>
      <div
        v-if="this.studyId !== 'wire' && this.studyId !== 'wire-23'"
        class="full-width row justify-center"
      >
        <FreeElectiveSelector
          v-if="studyLoaded"
          @update-free-elective="updateFreeElective"
          @removeFreeElective="removeFreeElective"
          :study_id="studyId"
          :studyObject="studyObject"
          :freeElectivess="freeElectivess"
          @allElectivesDone="allElectivesDone"
          :statusUpdated="statusUpdated"
        />
      </div>
    </div>
    <div
      class="fixed-bottom row justify-center q-ma-md"
      style="z-index: 6"
      v-if="userStore.loggedIn"
    >
      <LvCart
        :seamless="seamless"
        @update:seamless="updateSeamless"
        :droppedItems="droppedItems"
        :droppedEctsSum="droppedEctsSum"
        @drop="handleDrop"
        @removeDroppedItem="removeDroppedItem"
      />
    </div>

    <!-- Dialog -->
    <q-dialog v-model="showDialog" v-if="userStore.loggedIn">
      <q-card style="min-width: 350px">
        <q-card-section class="banner text-center">
          <q-img src="@/assets/celebration.svg" fit="cover" />
        </q-card-section>
        <q-card-section class="text-center">
          <div class="text-h5">{{ $t("studyplan.dialog_heading") }}</div>
        </q-card-section>
        <q-card-section class="text-body1 text-grey-8">
          {{ currentSubject.name }}
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input
            maxlength="1"
            label="Grade"
            type="number"
            :hint="$t('studyplan.dialog_hint')"
            autofocus
            outlined
            dense
            v-model="grade_dialog_value"
            :error="inputError"
            @keyup.enter="setStatus(grade_dialog_value)"
          />
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn
            flat
            :label="$t('studyplan.dialog_skip')"
            color="primary"
            @click="skipSetStatus"
          />
          <q-btn
            flat
            :label="$t('studyplan.dialog_done')"
            color="primary"
            :error="inputError"
            @click="setStatus"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import AddStudyButton from "@/components/AddStudyButton.vue";
import { useUserStore } from "@/store/user.store";
import { getChecker, getStudyObject, getStudyPath } from "@/store/study_logic";
import { useQuasar } from "quasar";
import { useLvStore } from "@/store/lv.store";
import SbwlCarousel from "@/components/study/SbwlCarousel.vue";
import FreeElectiveSelector from "@/components/study/FreeElectiveSelector.vue";
import LvCart from "@/components/lvplaner/LvCart.vue";
import Subject from "@/components/study/Subject.vue";
import Dashboard from "@/components/study/Dashboard.vue";

export default {
  components: {
    AddStudyButton,
    Subject,
    Dashboard,
    SbwlCarousel,
    FreeElectiveSelector,
    LvCart,
  },
  props: {
    study_id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let studyId = props.study_id || window.location.pathname.split("/")[2];

    if (!studyId) {
      throw new Error("No study id provided");
    }

    const userStore = useUserStore();
    const q = useQuasar();
    const changed = ref(false);
    const checker = getChecker(studyId);
    const studyObject = ref({ ...getStudyObject(studyId) });
    const lvStore = useLvStore();
    const path = getStudyPath(studyId);

    return {
      checker,
      studyObject,
      userStore,
      q,
      changed,
      studyId,
      lvStore,
      path,
      cartopen: ref(false),
      seamless: ref(false),
    };
  },
  data() {
    return {
      ectsCount: ref(0),
      isRunningCheckCBK: ref(false),
      checkHauptstudiumValue: true,
      twoLvCheck: ref(false),
      showDialog: ref(false),
      currentSubject: ref(null),
      droppedItems: ref([]),
      statusUpdated: ref(false),
      inputError: ref(false),
      grade_dialog_value: ref(null),
      display_model: ref("one"),

      status: ref([
        {
          title: "STEOP",
          bbeTitle: "Introductory and Orientation Phase",
          show: true,
          array: ref([
            /*
              _id: string,
              name: string,
              subject_type: string,
              ects: number,
              ch: number,
              color: string,
              status: string,
              grade: number,
              category: string
             */
          ]),
        },
        {
          title: "Common Body of Knowledge",
          show: true,
          array: ref([]),
        },
        {
          title: "Hauptstudium",
          bbeTitle: "Main Program Phase",
          show: true,
          array: ref([]),
        },
      ]),

      sbwlss: ref([]),
      studyLoaded: ref(false),
      freeElectivess: ref([]),
    };
  },
  methods: {
    async clickEvent(evt) {
      if (evt && evt.isTrusted) {
        //evt.stopPropagation();

        await this.userStore.addStudy(this.studyObject, this.q.notify);
        location.reload();

        this.changed = true;
      } else {
        // console.log("wrong clickEvent", evt);
      }
    },

    /* ects Counter */
    async ectsCounter() {
      let ectsSum = 0;
      for (let i = 0; i < this.studyObject.subjectStatusEntry.length; i++) {
        if (this.studyObject.subjectStatusEntry[i].status == "done") {
          const matchingSubject = this.studyObject.subjects.find(
            (subject) =>
              subject._id ==
                this.studyObject.subjectStatusEntry[i].subject_id &&
              subject.subject_type !== "SBWL"
          );
          if (matchingSubject) {
            ectsSum += matchingSubject.ects;
          }
        }
      }
      this.ectsCount = ectsSum;
    },
    async updateSbwl(sbwl) {
      console.log(sbwl, "sbwl")
      await this.userStore.addSbwl(this.studyId, sbwl.name, this.q.notify, sbwl);
      await this.userStore.updateStudyData();
      this.sbwlss = await this.userStore.getSbwls(this.studyId);
    },
    /**
     * Updates the status from a given subject of a given SBWL.
     * @param {Object} sbwl The SBWL object
     * @param {Number} idx The index of the SBWL subject that is being updated
     * @param {String} status The new status of the SBWL subject
     */
    async updateStatusSbwl(sbwl, idx, status) {
      // @TODO: @sevsev9: update the API to take only sbwl_name, idx and status to update a status

      // copy the states array
      let states = sbwl.states;

      // update the status of the subject
      states[idx].status = status;
      // update the states array in the store
      await this.userStore.setSbwlGrade(
        this.studyId,
        states,
        sbwl.sbwl_name,
        this.q.notify
      );
      await this.userStore.updateStudyData();
      this.sbwlss = await this.userStore.getSbwls(this.studyId);
    },
    async updateGradeSbwl(sbwl, idx, grade) {
      // copy the states array
      let states = sbwl.states;

      // update the grade of the subject
      states[idx].grade = grade;

      // update the states array in the store
      await this.userStore.setSbwlGrade(
        this.studyId,
        states,
        sbwl.sbwl_name,
        this.q.notify
      );
      await this.userStore.updateStudyData();
      this.sbwlss = await this.userStore.getSbwls(this.studyId);
    },
    async removeSbwl(sbwl) {
      console.log(sbwl, "remove sbwl")
      await this.userStore.removeSbwl(
        this.studyId,
        sbwl.sbwl_name,
        this.q.notify
      );
      await this.userStore.updateStudyData();
      this.sbwlss = await this.userStore.getSbwls(this.studyId);
    },
    async updateFreeElective(freeElective) {
      await this.userStore.addFreeElective(
        this.studyId,
        freeElective,
        this.q.notify
      );
      await this.userStore.updateStudyData();
      this.freeElectivess = await this.userStore.getFreeElectives(this.studyId);
    },
    async removeFreeElective(freeElective) {
      await this.userStore.removeFreeElective(
        this.studyId,
        freeElective,
        this.q.notify
      );
      await this.userStore.updateStudyData();
      this.freeElectivess = await this.userStore.getFreeElectives(this.studyId);
    },

    /* Loop über subjectStatusEntry - wenn von child emitted subject._id == id von subjectStatusEntry wird der Status mit dieser ID auf den .innerText vom gedrückten Button geändert */
    async updateStatus(subject, btn) {
      if (!this.showAddButton) {
        this.statusUpdated = false;
        if (btn == "done" && subject.status !== "done") {
          this.showDialog = true;
          this.currentSubject = subject;
        }

        for (let i = 0; i < this.studyObject.subjectStatusEntry.length; i++) {
          let id = this.studyObject.subjectStatusEntry[i].subject_id;
          if (subject._id == id) {
            this.studyObject.subjectStatusEntry[i].status = `${btn}`;
            if (id.status !== "done") {
              this.studyObject.subjects[i].grade = 0;
            }
          }
        }
        subject.status = `${btn}`;

        //set grade to 0 if status is not done
        if (subject.status !== "done") {
          subject.grade = 0;
          await this.userStore.updateStudy(
            this.studyId,
            subject._id,
            subject.status,
            0,
            this.q.notify
          );
        } else {
          // update subject stats (status, grade) of the current subject
          await this.userStore.updateStudy(
            this.studyId,
            subject._id,
            btn,
            subject.grade || 0,
            this.q.notify
          );
        }
        this.updateFunction();
        // update the other subjects stats (status, grade) relative to the current subject
        await this.processUpdates();
        this.statusUpdated = true;
      } else {
        this.q.notify({
          message: "Please add the study first",
          color: "red-5",
          icon: "report_problem",
        });
      }
    },

    /**
     * Führt zuerst mal executeAll aus und updated dann die Subjects.
     * Dann wird über die zurückgegeben Arrays iteriert.
     * Jedes mal wenn durch ein Array etwas verändert wird werden die updates angepasst und mit den neuen werten executeAll ausgeführt.
     * Am ende wird werden die arrays zu einem array zusammengefügt und die mit setBulkSubjects in die Datenbank geschrieben.
     * Dann wird mit updateFunction die Study aktualisiert.
     *
     */
    async processUpdates() {
      let update_array = await this.checker.executeAll(
        this.studyObject,
        this.$data,
        this.isRunningCheckCBK,
        this.twoLvCheck,
        this.ectsCount
      );
      await this.ectsCounter();

      let newStudyObject = this.studyObject;

      for (
        let updateIndex = 0;
        updateIndex < update_array.length;
        updateIndex++
      ) {
        const update = update_array[updateIndex];
        const arrayName = Object.keys(update)[0]; // Den Namen des Arrays aus dem Update extrahieren
        const updates = update[arrayName]; // Array von Updates für das bestimmte Array
        // Durch jedes Objekt im Update-Array iterieren
        for (const updateObj of updates) {
          // Die entsprechende subject_id im newStudyObject finden und aktualisieren
          const subjectToUpdate = newStudyObject.subjectStatusEntry.find(
            (entry) => entry.subject_id === updateObj.subject_id
          );
          if (subjectToUpdate) {
            // Status und andere Felder aktualisieren
            if (Object.prototype.hasOwnProperty.call(updateObj, "status")) {
              subjectToUpdate.status = updateObj.status;
            }
            if (Object.prototype.hasOwnProperty.call(updateObj, "grade")) {
              subjectToUpdate.grade = updateObj.grade || 0;
            }
            // Weitere Felder können hier aktualisiert werden
          }
        }
        // ExecuteAll mit dem aktualisierten newStudyObject ausführen
        update_array = await this.checker.executeAll(
          newStudyObject,
          this.$data,
          this.isRunningCheckCBK,
          this.twoLvCheck,
          this.ectsCount
        );
      }

      await this.userStore.setBulkSubject(
        this.studyId,
        newStudyObject.subjectStatusEntry,
        this.q.notify
      );
    },

    /**
     * This method is called from the "Set Grade" Dialog, when the user decides to skip setting the grade.
     */
    async skipSetStatus() {
      this.grade_dialog_value = 0;

      await this.setStatus();
    },

    /**
     * This method is only called, when the user decides to set a grade when completing a subject.
     * => State is always "done";
     */
    async setStatus() {
      try {
        const subject = this.currentSubject;

        // parse grade to because vue event bus converts everything to string.
        let grade = parseInt(this.grade_dialog_value);

        if (grade > 5 || grade < 0) {
          // 0: grade not set
          throw new Error("Grade out of range");
        } else if (grade == null || isNaN(grade)) {
          this.inputError = true;
          return;
        }
        this.inputError = false;

        await this.userStore.updateStudy(
          this.studyId,
          subject._id,
          "done",
          grade,
          this.q.notify
        );
        await this.updateFunction();

        // update the subject status entries
        this.studyObject.subjectStatusEntry.forEach((e, idx, arr) =>
          e.subject_id === subject._id
            ? ((arr[idx].status = "done"), (arr[idx].grade = grade))
            : null
        );
        this.showDialog = false;
        this.grade_dialog_value = null;
      } catch (e) {
        this.q.notify({
          message: "Grade out of range",
          color: "red-5",
          icon: "report_problem",
        });
      }
    },

    /**
     * Update Grade geht einher mit dem Update des Status auf done
     * (ansonsten hat man keine Möglichkeit die Note zu ändern)
     *
     * @param {typeof<Subject && SubjectStatus>} subject
     * @param {string} grade String representation of a number (vuejs event bus things)
     */
    async updateGrade(subject, scope) {
      try {
        let grade = parseFloat(scope);

        if (grade > 5 || grade < 0) {
          // 0: grade not set
          throw new Error("Grade out of range");
        }

        await this.userStore.updateStudy(
          this.studyId,
          subject._id,
          subject.status,
          grade,
          this.q.notify
        );
        await this.userStore.updateStudyData();
        this.updateFunction();
      } catch (e) {
        this.q.notify({
          message: "Grade out of range",
          color: "red-5",
          icon: "report_problem",
        });
        return;
      }
    },
    /* Zeigt den Path (Dependencies) des jeweiligen Studiengangs an */
    showPath(subject, event) {
      subject.active = true;

      const detectClick = (e) => {
        if (e.target !== event.target) {
          this.status.forEach((array) => {
            array.array.forEach((same) => {
              same.active = false;
            });
          });
          document.removeEventListener("click", detectClick);
        } else {
          subject.active = true;
        }

        if (subject.active) {
          // subject.color = "yellow"

          /* nimmt aus store/index.js den getStudyPath;
         getStudyPath nimmt aus store/paths den jeweiligen path.js abhängig von der studyId */

          this.path.executePath(subject, this.status);
        } else {
          const same = this.studyObject.subjects.find(
            (s) => s._id == subject._id
          );
          if (same) {
            subject.color = same.color;
          }
          this.status.forEach((array) => {
            array.array.forEach((same) => {
              this.studyObject.subjects.forEach((status) => {
                if (same._id == status._id) {
                  same.color = status.color;
                }
              });
            });
          });
        }
      };
      document.addEventListener("click", detectClick);
    },
    async allElectivesDone(allElectivesDone) {
      let elective = {};
      await this.studyObject.study.subjects.forEach((el) => {
        if (
          el.category == "Freies Wahlfach" ||
          el.category == "Free Electives and Internship"
        ) {
          const subject = this.studyObject.subjectStatusEntry.find(
            (matchingElective) => matchingElective.subject_id == el.subject_id
          );
          elective = subject;
          if (elective.status == "can-do" && allElectivesDone) {
            elective.status = "done";
          } else if (!allElectivesDone && elective.status !== "unavailable") {
            elective.status = "can-do";
          }
        }
        this.userStore.updateStudy(
          this.studyId,
          elective._id,
          elective.status,
          this.q.notify
        );
      });
    },
    async allSbwlsDone(allDone) {
      let sbwls = [];
      this.studyObject.study.subjects.forEach((sbwl) => {
        if (
          sbwl.category == "Spezielle Betriebswirtschaftslehre" ||
          sbwl.category == "Specializations"
        ) {
          const matchingStatus = this.studyObject.subjectStatusEntry.find(
            (matchingSbwl) => matchingSbwl.subject_id == sbwl.subject_id
          );
          sbwls.push(matchingStatus);
        }
      });
      if (sbwls[0]) {
        if (sbwls[0].status == "can-do" && allDone.firstSbwl) {
          sbwls[0].status = "done";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[0].subject_id,
            sbwls[0].status,
            sbwls[0].grade,
            this.q.notify
          );
        } else if (sbwls[0].status == "done" && !allDone.firstSbwl) {
          sbwls[0].status = "can-do";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[0].subject_id,
            sbwls[0].status,
            sbwls[0].grade,
            this.q.notify
          );
        }
      } else {
        return;
      }
      if (sbwls[1]) {
        if (sbwls[1].status == "can-do" && allDone.secondSbwl) {
          sbwls[1].status = "done";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[1].subject_id,
            sbwls[1].status,
            sbwls[1].grade,
            this.q.notify
          );
        } else if (sbwls[1].status == "done" && !allDone.secondSbwl) {
          sbwls[1].status = "can-do";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[1].subject_id,
            sbwls[1].status,
            sbwls[1].grade,
            this.q.notify
          );
        }
      } else {
        return;
      }
      if (sbwls[2]) {
        if (sbwls[2].status == "can-do" && allDone.thirdSbwl) {
          sbwls[2].status = "done";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[2].subject_id,
            sbwls[2].status,
            sbwls[2].grade,
            this.q.notify
          );
        } else if (sbwls[2].status == "done" && !allDone.thirdSbwl) {
          sbwls[2].status = "can-do";
          await this.userStore.updateStudy(
            this.studyId,
            sbwls[2].subject_id,
            sbwls[2].status,
            sbwls[2].grade,
            this.q.notify
          );
        }
      } else {
        return;
      }
      console.log(sbwls[0])
    },
    updateSeamless(value) {
      this.seamless = value;
    },
    handleDrag(evt) {
      evt.preventDefault();
      /*nimmt drag kopie die in handleStart erstellt wird setzt opacity auf 1 und die position */
      const copyCard = document.querySelector(".drag-image");
      copyCard.style.opacity = "1";
      copyCard.style.left = evt.pageX - 100 + "px";
      copyCard.style.top = evt.pageY - 100 + "px";
    },
    handleDragStart(evt, subject) {
      /* Öffnet Dialog */
      this.seamless = true;

      /* Code für den dataTransfer der Subjects */
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("SubjectName", subject.name);
      evt.dataTransfer.setData("SubjectEcts", subject.ects);

      /* Code für die Drag Kopie */
      const copyCard = document.createElement("div");
      copyCard.className = "drag-image";
      copyCard.innerHTML = evt.target.innerHTML;
      document.body.appendChild(copyCard);
      copyCard.style.opacity = "0";
      /*nimmt drag kopie die in handleStart erstellt wird und ändert das Design */
      copyCard.style.position = "absolute";
      copyCard.style.width = "15rem";
      copyCard.style.height = "10rem";
      copyCard.style.borderRadius = "6px";
      copyCard.style.boxShadow =
        "0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)";
      copyCard.style.fontSize = "1px !important";
      copyCard.style.backgroundColor = "white";
      copyCard.style.zIndex = "5000";
      copyCard.style.pointerEvents = "none";

      /* Sucht in der Drag Kopie die Action Buttons unten und entfernt sie */
      const zweitesDiv = copyCard.querySelector(
        ".q-card__section.q-card__section--vert.items"
      );

      // Überprüfe, ob das zweite Div gefunden wurde, und entferne es, falls es existiert
      if (zweitesDiv) {
        zweitesDiv.parentNode.removeChild(zweitesDiv);
      }

      /* Verkleinert die Schriftgröße */
      const erstesDiv = copyCard.querySelector(
        ".q-card__section.q-card__section--vert.banner.column.justify-end.items-start.q-pa-sm.cursor-pointer"
      );
      if (erstesDiv) {
        // Finde das zweite Kind (Child) im ersten Haupt-Div
        const zweitesKind = erstesDiv.children[1]; // Beachte, dass die Indexierung bei 0 beginnt

        // Überprüfe, ob das zweite Kind gefunden wurde, und ändere die Schriftgröße, falls es existiert
        if (zweitesKind) {
          zweitesKind.style.fontSize = "16px"; // Hier kannst du die gewünschte Schriftgröße einstellen
        }
      }
      evt.dataTransfer.setDragImage(copyCard, 0, 0);
    },
    handleDragEnd() {
      //this.seamless = false
      /* Entfernt Copycard wieder */
      const copyCard = document.querySelector(".drag-image");
      document.body.removeChild(copyCard);
    },
    handleDrop(evt) {
      let name = evt.dataTransfer.getData("SubjectName");
      let ects = evt.dataTransfer.getData("SubjectEcts");
      if (!this.droppedItems.some((item) => item.name == name)) {
        this.droppedItems.push({ name, ects });
        this.lvStore.addToCart(name, ects);
      }
    },
    /* Wenn copycard über add Button gehoverd wird
    handleEnter() {
      this.seamless = true
    },*/
    /*handleLeave() {
      this.seamless = false
    },*/
    removeDroppedItem(index) {
      this.droppedItems.splice(index, 1);
      this.lvStore.removeFromCart(index);
    },
    async updateFunction() {
      const sse = await this.userStore.getSubjectEntries(
        this.studyObject.study._id
      );
      sse.forEach((entry) => {
        if (!Object.hasOwnProperty.call(entry, "grade")) {
          entry.grade = 0;
        }
      });
      this.studyObject.subjectStatusEntry = sse;
      this.q.notify({
        title: "Study updated.",
        message: "The study was updated successfully",
        color: "positive",
        badgeStyle: "display: none;",
        timeout: 1000,
      });
    },
  },
  watch: {
    steop: {
      async handler() {},
      deep: true,
    },
  },
  computed: {
    steop() {
      return this.studyObject.subjectStatusEntry.slice(0, 4);
    },
    cbk() {
      return this.studyObject.subjectStatusEntry.slice(3, 13);
    },
    hauptstudium() {
      return this.studyObject.subjectStatusEntry.slice(13);
    },

    statusArray_old() {
      this.studyObject.study.subjects.forEach(
        (
          entry /* { subject_id: number, category: String, phase: STEOP | CBK | Hauptstudium */
        ) => {
          const subject = this.studyObject.subjects.find(
            (s) => s._id == entry.subject_id
          );

          if (subject) {
            const matchingStatus = this.status.find(
              (s) => s.title == entry.phase
            );
            if (matchingStatus) {
              const existingIndex = matchingStatus.array.findIndex(
                (s) => s._id == subject._id
              );
              if (existingIndex !== -1) {
                matchingStatus.array[existingIndex].name = subject.name;
                matchingStatus.array[existingIndex].category = entry.category;
              } else {
                matchingStatus.array.push({
                  // subject properties
                  _id: subject._id,
                  name: subject.name,
                  subject_type: subject.subject_type,
                  ects: subject.ects,
                  ch: subject.ch,
                  color: subject.color,
                  active: false,
                  grade: subject.grade,

                  // subject status entry properties
                  phase: entry.phase,
                  category: entry.category,
                });
              }
              const statusEntry = this.studyObject.subjectStatusEntry.find(
                (se) => se.subject_id == entry.subject_id
              );

              if (statusEntry) {
                matchingStatus.array.find((s) => s._id == subject._id).status =
                  statusEntry.status;
                matchingStatus.array.find((s) => s._id == subject._id).grade =
                  statusEntry.grade;
              }
            }
          }
        }
      );
      return this.status;
    },

    /**
     * Method to update the `status` property of the context object.
     * This method iterates over the `subjectStatusEntry` property of the `studyObject`
     * and for each entry, finds the corresponding subject and status.
     * If the subject and status exist, it will update the status's array.
     * Finally, it filters each status array to retain only subjects found in `subjectStatusEntry`.
     *
     * Compared to the old function this algorithm has a better runtime complexity (O(n²) > O(n)).
     *
     * @TODO: fix bug, where status array function returns nothing.
     *
     * @returns {Array} - Updated status array
     */

    fontSize() {
      return this.$q.platform.is.desktop ? "text-h3" : "text-h5";
    },
    barPadding() {
      return this.$q.platform.is.desktop ? "q-pa-md" : "q-pa-lg";
    },
    individualFontSize() {
      return this.$q.platform.is.desktop
        ? "individual-fontsize q-pl-md"
        : "individual-fontsize-mobile q-pl-xs";
    },
    individualFontSizeSmall() {
      return this.$q.platform.is.desktop
        ? "text-body1"
        : "individual-fontsize-mobile-small";
    },
    iconSize() {
      return this.$q.platform.is.desktop ? "md" : "xs";
    },
    iconPadding() {
      return this.$q.platform.is.desktop ? "q-pl-lx" : "q-pl-xs";
    },
    colSize() {
      if (this.$q.platform.is.desktop) {
        return "col-xs-12 col-md-3";
      } else if (this.$q.screen.lt.sm) {
        return "col-12";
      } else if (this.$q.screen.lt.md) {
        return "col-6";
      } else {
        return "col-xs-12 col-md-3";
      }
    },
    droppedEctsSum() {
      return this.droppedItems.reduce(
        (total, subject) => total + parseInt(subject.ects),
        0
      );
    },
    showAddButton() {
      return (
        !this.changed &&
        this.userStore.getShowAddStudyButton(this.studyObject.study._id)
      );
    },
  },

  async mounted() {
    /* Wenn studyId = "bbe" dann wird CBK gehided */
    const container = document.querySelector(".container");
    const secondChild = container.children[1];
    if (this.studyId == "bbe") {
      secondChild.style.display = "none";
    }
    if (this.userStore.loggedIn) {
      // refetch user
      await this.userStore.fetchUser();

      // if user is registered for study
      if (this.userStore.getStudy(this.studyObject.study._id)) {
        // get subject status entries for this study
        const sse = await this.userStore.getSubjectEntries(
          this.studyObject.study._id
        );

        sse.forEach((entry) => {
          if (!Object.hasOwnProperty.call(entry, "grade")) {
            entry.grade = 0;
          }
        });

        this.studyObject.subjectStatusEntry = sse;
        this.sbwlss = await this.userStore.getSbwls(this.studyObject.study._id);
        this.freeElectivess = await this.userStore.getFreeElectives(
          this.studyObject.study._id
        );
      }

      // user is not registered for study, showing default status entries
      else {
        this.studyObject.subjectStatusEntry = [
          ...getStudyObject(this.studyId).subjectStatusEntry,
        ];

        // warn user, that he is not registered for this study and changes will not be saved
        this.q.notify({
          progress: true,
          message: this.$t("studyplan.not_added_study_notification"),
          color: "orange-5",
          icon: "warning",
        });
      }
      this.studyLoaded = true;
    }
  },
  async updated() {
    //await this.userStore.updateStudyData()
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.individual-fontsize {
  font-size: 23px;
}

.individual-fontsize-mobile {
  font-size: 15px;
}

.individual-fontsize-mobile-small {
  font-size: 10px;
}

#heading-info-h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  color: $blue-7;
}

#heading-info-h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  color: #5bbdf4;
}

#heading-info-h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  color: $blue-7;
  font-size: 20px;
}

.margin-lg-div {
  margin-bottom: 100px;
  margin-top: 50px;
}

.individual-bg {
  background-color: #fbfdff;
  transition: ease 0.2s;
  min-height: 35px;
}

.individual-bg:hover {
  filter: brightness(98%);
}
</style>
