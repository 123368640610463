<template>
  <div class="row justify-center">
        <q-btn size="md" @click="emitClick" style="background: #fcf5eb; color: #F5C104; letter-spacing: 0.1px; font-family: Poppins, sans-serif; font-weight: bold"  :label="$t('addstudybutton.label')" />
    </div>
</template>

<script>
export default {
  methods: {
    emitClick() {
      this.$emit('click')
    }
  }
}
</script>

<style>
</style>