<template>
  <div class="q-mx-md">
    <div class="row justify-center">
      <div class="col-xs-12 col-md-6">
        <div class="text-h4 text-blue-4 text-center q-mb-lg">Dashboard</div>
        <div class="row">
          <div class="col-12">
            <q-card class="my-card q-mb-md">
              <q-card-section>
                <div class="text-h5"> {{$t('studyplan.dashboard_heading')}} </div>
                <q-linear-progress
                  rounded
                  stripe
                  size="25px"
                  :value="progress"
                  color="blue-4"
                  track-color="grey-5"
                  class="q-mt-lg"
                >
                  <div class="absolute-full flex justify-end">
                    <q-badge text-color="black" :label=" '/' + ' ' + 180" style="background: transparent" />
                  </div>
                </q-linear-progress>
              </q-card-section>
              <q-card-section horizontal class="justify-evenly">
                <q-card-section>
                  <div class="text-center">
                    <div class="text-h4">{{ completed_subjects }}</div>
                    <div class="text-caption"> {{$t('studyplan.dashboard_subjects')}} </div>
                  </div>
                </q-card-section>
                <q-separator vertical inset />
                <q-card-section>
                  <div class="text-center">
                    <div class="text-h4">{{ current_ects }}</div>
                    <div class="text-caption">ECTS</div>
                  </div>
                </q-card-section>
                <q-separator vertical inset />
                <q-card-section>
                  <div class="text-center">
                    <div class="text-h4">{{ gpa }}</div>
                    <div v-if="this.checkGradeInsert" class="grade-hint text-amber-7">
                      <q-icon name="info" />
                      <span class="text-caption"> {{$t('studyplan.dashboard_grade_check') }} </span>
                    </div>
                    <div class="text-caption">GPA</div>
                  </div>
                </q-card-section>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="row q-col-gutter-x-md justify-center">
          <div class="col-xs-12 col-md-6">
            <q-card class="my-card">
              <q-card-section>
                <div class="text-h5"> {{$t('studyplan.dashboard_current_semester')}} </div>
              </q-card-section>
              <q-card-section horizontal class="justify-evenly">
                <q-card-section>
                  <div class="text-center">
                    <div class="text-h4">{{ current_subjects }}</div>
                    <div class="text-caption">{{$t('studyplan.dashboard_subjects')}}</div>
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-center">
                    <div class="text-h4">{{ semester_ects }}</div>
                    <div class="text-caption">ECTS</div>
                  </div>
                </q-card-section>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    return {
      step: ref(),
    };
  },
  props: {
    /*
    current_ects: Zeigt die Summe der ECTS der Subjects an die sich aktuell in Current befinden bzw. die den Status "doing" haben.

    current_subjects: Zeigt die Anzahl der Subjects an die sich aktuell in Current befinden bzw. die den Status "doing" haben.

    gpa: Zeigt den Notendurchschnitt an -> Die Noten aus allen Subjects in "Completed" (Status: "done") summiert und dividiert durch die Anzahl der Fächer.

    semester_ects: Zeigt die Summe der ECTS an von allen Subjects die in "Completed" (Status: "done") sind.

    completed_subjects: Zeigt die Summe der Anzahl der Subjects die sich in "Completed" (Status: "done") befinden. 

    min_ects / max_ects: Geben die Range für den Circle bei Summe ECTS an (0 bis 180)
    */

    semester_ects: { type: Number, required: true },
    current_subjects: { type: Number, required: true },
    gpa: { type: Number, required: true },
    min_ects: { type: Number, required: true },
    max_ects: { type: Number, required: true },
    current_ects: { type: Number, required: true },
    completed_subjects: { type: Number, required: true },
    checkGradeInsert: {type: Boolean, required: true}
  },
  computed: {
    progress() {
      return this.current_ects / 180
    }
  }
};
</script>

<style scoped>
</style>