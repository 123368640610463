<template>
  <div
    :class="
      [wahlfächer.show ? 'col-md-12 full-width ' : 'col-md-6'] +
      ' col-xs-12 shadow-1 row justify-center col-6 q-mt-lg q-pa-md rounded-borders item section'
    "
    style="width: 50%"
  >
    <div class="row justify-start items-center full-width">
      <div class="col-xs-4">
        <q-toolbar class="text-blue-7" style="backgroundcolor: #f6f8fa">
          <q-toolbar-title class="text-h6 text-bold q-pa-sm">
            {{ $t("studyplan.freeElectives") }}
          </q-toolbar-title>
        </q-toolbar>
      </div>
      <div class="col-xs-4">
        <q-toolbar
          class="text-blue-7 text-center"
          style="backgroundcolor: #f6f8fa"
        >
          <q-toolbar-title class="text-body1 q-pa-sm">
            {{ this.checkEctsSum }} / {{ getFreeElectivesECTS() }}
          </q-toolbar-title>
        </q-toolbar>
      </div>
      <div class="col-xs-4">
        <div class="row justify-end items-end">
          <q-icon
            @click="this.wahlfächer.show = !wahlfächer.show"
            class="rounded-borders individual-bg cursor-pointer"
            :name="wahlfächer.show ? 'expand_less' : 'expand_more'"
            color="blue-7"
            :size="iconSize"
          ></q-icon>
        </div>
      </div>
    </div>
    <div v-if="wahlfächer.show" class="row justify-center full-width">
      <div class="q-mb-md">
        <q-btn
          :disable="this.checkEctsSum >= getFreeElectivesECTS()"
          :color="
            this.checkEctsSum >= getFreeElectivesECTS() ? 'grey-5' : 'blue-7'
          "
          :label="$t('studyplan.freeElectives_button')"
          @click="showDialog = true"
        >
          <q-icon class="q-pl-sm" name="add_circle" />
        </q-btn>
        <q-tooltip v-if="this.checkEctsSum >= getFreeElectivesECTS()">
          {{ $t("studyplan.freeElectives_ects") }}
        </q-tooltip>
      </div>
      <!-- Dialog -->
      <q-dialog v-model="showDialog" v-if="this.checkElectivStatus.length > 0">
        <q-card style="width: 700px; max-width: 80vw">
          <q-card-section>
            <div class="text-h6 text-bold text-blue-7">
              {{ $t("studyplan.freeElectives_button") }}
            </div>
          </q-card-section>

          <q-card-section class="row justify-start q-col-gutter-xl q-pt-none">
            <!-- Name -->
            <div class="col-6">
              <q-input
                filled
                v-model="name"
                type="text"
                label="Name"
                hint="Name der Lehrveranstaltung"
              />
            </div>
            <!-- ECTS -->
            <div class="col-6">
              <q-input
                filled
                v-model="ects"
                type="number"
                label="ECTS"
                hint="ECTS-Punkte"
                :rules="[validateECTS]"
              />
            </div>
          </q-card-section>
          <q-card-section class="row justify-start">
            <!-- TYPE -->
            <div>
              <q-btn-dropdown color="amber-5" :label="getTypeLabel">
                <q-list>
                  <q-item
                    v-model="selectedType"
                    v-for="subject_type in subject_types"
                    :key="subject_type"
                    clickable
                    v-close-popup
                    @click="selectedType = subject_type"
                  >
                    <q-item-section>
                      <q-item-label>{{ subject_type }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
            <!-- GRADE -->
            <div class="q-pl-md">
              <q-btn-dropdown color="amber-5" :label="getGradelabel">
                <q-list>
                  <q-item
                    v-model="selectedGrade"
                    v-for="grade in grades"
                    :key="grade"
                    clickable
                    v-close-popup
                    @click="selectedGrade = grade"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ grade }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              flat
              color="negative"
              :label="$t('common.close_button')"
              v-close-popup
            />
            <q-btn
              flat
              color="positive"
              :label="$t('common.send_button')"
              @click="addWahlfach"
              v-close-popup
              :disable="!checkHinzufügen"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-dialog
        v-model="showDialog"
        v-else-if="this.checkElectivStatus.length == 0"
      >
        <q-card style="width: 700px; max-width: 80vw">
          <q-card-section>
            <div class="text-h6 text-bold text-center text-blue-7">
              {{ $t("studyplan.freeElectives_not_available_heading") }}
            </div>
          </q-card-section>
          <q-card-section>
            <div class="text-body1">
              {{ $t("studyplan.freeElectives_not_available_info") }}
            </div>
          </q-card-section>
          <q-card-section>
            <div class="text-subtitle2">
              {{ $t("studyplan.freeElectives_not_available_tip") }}
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat color="negative" v-close-popup>
              {{ $t("common.close_button") }}
            </q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
    <!-- Wahlfächer Liste -->
    <div class="row q-col-gutter-x-md full-width" v-if="wahlfächer.show">
      <div
        :class="colSize"
        v-for="(freeElective, i) in this.freeElectives"
        :key="i"
        style="min-height: 250px"
      >
        <FreeElectiveSubject
          :freeElective="freeElective"
          @removeFreeElective="removeFreeElective(freeElective)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getChecker } from "@/store/study_logic";
import { ref } from "vue";
import FreeElectiveSubject from "./FreeElectiveSubject.vue";
import { useUserStore } from "@/store/user.store";
import { useQuasar } from "quasar";
export default {
  components: {
    FreeElectiveSubject,
  },
  props: {
    study_id: {
      type: String,
      required: true,
    },
    studyObject: {
      type: Object,
      required: true,
    },
    freeElectivess: {
      type: Array,
      required: true,
    },
    statusUpdated: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    let studyId = props.study_id || window.location.pathname.split("/")[2];

    if (!studyId) {
      throw new Error("No study id provided");
    }
    const checker = getChecker(studyId);
    const userStore = useUserStore();
    const q = useQuasar();
    return {
      studyId,
      showDialog: ref(false),
      checker,
      userStore,
      q,
      test: ref(false)
    };
  },

  data() {
    return {
      name: ref(""),
      ects: ref(""),
      grades: ref(["1", "2", "3", "4", "Teilgenommen"]),
      subject_types: ref(["LVP", "VUE", "PI", "Any"]),
      selectedType: ref(""),
      selectedGrade: ref(""),

      freeElectives: ref([]),

      wahlfächer: ref({
        title: "Freie Wahlfächer",
        show: true,
        array: ref([]),
      }),
    };
  },
  computed: {
    checkElectivStatus() {
      const matchingStatus = [];
      const electiv = this.studyObject.study.subjects.filter(
        (obj) =>
          obj.category == "Freies Wahlfach" ||
          obj.category == "Free Electives and Internship"
      );
      if (!electiv) {
        return false;
      }
      electiv.forEach((obj) => {
        const entry = this.studyObject.subjectStatusEntry.find(
          (el) => el.subject_id == obj.subject_id
        );
        if (entry.status == "can-do" || entry.status == "done") {
          matchingStatus.push(entry);
        }
      });
      return matchingStatus;
    },
    iconSize() {
      return this.$q.platform.is.desktop ? "md" : "xs";
    },
    getGradelabel() {
      return this.selectedGrade == ""
        ? "Grade"
        : "Grade:" + " " + this.selectedGrade;
    },
    getTypeLabel() {
      return this.selectedType == ""
        ? "Type"
        : "Type:" + " " + this.selectedType;
    },
    checkHinzufügen() {
      if (this.name && this.ects && this.selectedGrade && this.selectedType) {
        return true;
      } else {
        return false;
      }
    },
    colSize() {
      if (this.$q.platform.is.desktop) {
        return "col-xs-12 col-md-3";
      } else if (this.$q.screen.lt.sm) {
        return "col-12";
      } else if (this.$q.screen.lt.md) {
        return "col-6";
      } else {
        return "col-xs-12 col-md-3";
      }
    },
    checkEctsSum() {
      let ectsSum = 0;
      let allElectivesDone = false;
      for (let i = 0; i < this.freeElectives.length; i++) {
        ectsSum += parseFloat(this.freeElectives[i].ects);
      }
      if (ectsSum >= this.getFreeElectivesECTS()) {
        allElectivesDone = true;
        this.$emit("allElectivesDone", allElectivesDone);
      } else if (ectsSum < this.getFreeElectivesECTS()) {
        allElectivesDone = false;
        this.$emit("allElectivesDone", allElectivesDone);
      }
      return ectsSum;
    },
  },

  methods: {
    addWahlfach() {
  if (this.name && this.ects && this.selectedGrade && this.selectedType) {
    let grade = parseInt(this.selectedGrade, 10);
    if (isNaN(grade)) {
      grade = 0; // Wenn selectedGrade keine Zahl ist, setze es auf 0
    }
    const newFreeElective = {
      name: this.name,
      subject_type: this.selectedType,
      ects: parseInt(this.ects, 10),
      grade: grade,
    };
    this.freeElectives.push(newFreeElective);
    this.$emit("update-free-elective", newFreeElective);
    this.name = "";
    this.ects = "";
    this.selectedGrade = "";
    this.selectedType = "";
  } else {
    this.name = "";
    this.ects = null;
    this.selectedGrade = "";
    this.selectedType = "";
  }
},
    removeFreeElective(freeElective) {
      const index = this.freeElectives.findIndex(
        (el) => el.name === freeElective.name
      );
      if (index !== -1) {
        this.freeElectives.splice(index, 1);
      }
      this.$emit("removeFreeElective", freeElective);
    },
    getFreeElectivesECTS() {
      const wahlfach = this.studyObject.study.subjects.find(
        (sub) =>
          sub.category == "Freies Wahlfach" ||
          sub.category == "Free Electives and Internship"
      );

      if (wahlfach) {
        const wahlfachId = wahlfach.subject_id;

        const foundWahlfach = this.studyObject.subjects.find(
          (sub) => sub._id == wahlfachId
        );

        return foundWahlfach.ects;
      }
      return 0;
    },
    validateECTS(value) {
      const maxValue = this.getFreeElectivesECTS();
      const isValid = !isNaN(value) && value >= 0 && value <= maxValue;

      return isValid || `Die ECTS müssen zwischen 0 und ${maxValue} liegen.`;
    },
  },
  watch: {
    statusUpdated: {
      handler() {
        if (this.statusUpdated) {
          if (this.checkElectivStatus.length == 0) {
            if (this.freeElectives.length > 0) {
              this.freeElectives.forEach((el) => {
                this.$emit("removeFreeElective", el);
              });
              this.freeElectives = [];
            } else {
              this.freeElectives = [];
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.freeElectives = this.freeElectivess;
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}
</style>
