<template>
  <!-- Card Banner mit Category Title -->
  <q-card class="my-card">
    <!-- Add Badge for Wahlfach -->
    <q-card-section class="banner column justify-end items-start q-pa-sm"
      :style="{ backgroundColor: getSubjectColor(course) }">
      <q-badge text-color="grey-7" class="absolute-top-right q-pa-sm" color="transparent" align="top">
        SBWL
      </q-badge>

      <div :class="fontSize"> {{$t('studyplan.sbwlsubjects_course')}} {{ course_index + 1 }}</div>
    </q-card-section>

    <q-separator />
    <!-- Card lower part Info -->
    <q-card-section class="items">
      <!-- Course Info -->
      <div class="row items-end">
        <div class="col-8">
          <div class="row justify-start no-wrap">
            <div :class="itemSize + ' col-3'" :style="{ backgroundColor: getSubjectColor(course) }">
              <p class="upper-text">ANY</p>
              <p class="lower-text">{{$t('studyplan.subject_type')}}</p>
            </div>
            <div :class="itemSize + ' col-3'" :style="{ backgroundColor: getSubjectColor(course) }">
              <p class="upper-text"> {{ course.ects }} </p>
              <p class="lower-text">ECTS</p>
            </div>
            <!-- SST wird gehided -->
            <div style="display: none" :class="itemSize + ' col-3'" :style="{ backgroundColor: getSubjectColor(course) }">
              <p class="upper-text">SSt</p>
              <p class="lower-text">SSt</p>
            </div>
            <div :class="itemSize + ' col-3'" :style="{ backgroundColor: getSubjectColor(course), cursor: 'pointer' }">
              <q-tooltip v-if="course.status == 'can-do'"> {{$t('studyplan.subject_grade_requirement')}} </q-tooltip>
              <q-tooltip v-else-if="course.status == 'done'"> {{$t('studyplan.subject_grade_info')}} </q-tooltip>
              <p class="upper-text">{{ course.grade && course.grade > 0 ? course.grade : $t('studyplan.subject_grade') }}</p>
              <p v-if="course.grade && course.grade > 0" class="lower-text"> {{$t('studyplan.subject_grade')}} </p>

               <q-popup-edit
                v-if="course.status == 'done'"
                v-model="grade"
                class="text-grey-9"
                transition-show="flip-up"
                transition-hide="flip-down"
                v-slot="scope"
              >
                <q-input
                  :hint="$t('studyplan.subject_grade_info2')"
                  color="grey-9"
                  type="number"
                  v-model="scope.value"
                  dense
                  autofocus
                  @keyup.enter="updateGrade(course, scope)"
                >
                  <template v-slot:append>
                    <q-btn
                      flat
                      icon="edit"
                      @click="updateGrade(course, scope)"
                    />
                  </template>
                </q-input>
              </q-popup-edit> 
            </div>
          </div>
        </div>
        <div class="col-1"></div>

        <!-- More Buttom -->
        <div class="col-3">
          <div class="row justify-end">
            <q-btn-dropdown class="shadow-1" style="background-color: #5bbdf4" flat text-color="white" :label="buttonText"
              dense padding="sm" :size="buttonSize">
              <q-list v-for="button in buttons" :key="button">
                <q-item clickable v-close-popup @click="updateStatusSbwl(course, button)">
                  <q-item-section>
                    <q-item-label>{{ button }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    course: {
      type: Object,
      required: true
    },
    course_index: {
      type: Number,
      required: true
    },
    sbwl: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    return {
      buttons: ["Doing", "Done", "Undo"],
      grade: ref(props.course.grade),
      ...props
    };
  },
  methods: {
    updateStatusSbwl(course, button) {
      let status = button.toLowerCase();
      // remap because status is defined differentky
      if (status == "undo") {
        status = "can-do";
        course.grade = 0;
      }

      course.status = status;

      this.$emit("update-status-sbwl", this.sbwl, this.course_index, status);
    },
    getSubjectColor(course) {
      let color = "#FFF9C4"
      //console.log(this.sbwl.states)
      if (course.status == "doing") {
        color = "#FFA03D";
      } else if (course.status == "done") {
        color = "#98F280";
      } else {
        color = "#FFF9C4";
      }
      return color || "#FFF9C4";
    },
    updateGrade(course, scope) {
      course.grade = parseInt(scope.value);
      scope.set(false);
      this.$emit("update-grade-sbwl", this.sbwl, this.course_index, course.grade);
      },
  },
  computed: {
    fontSize() {
      return this.$q.screen.lt.md
        ? "category-title text-h5"
        : "category-title-small text-h6";
    },
    itemSize() {
      return this.$q.platform.is.desktop
        ? "item shadow-1"
        : "item-small shadow-1";
    },
    buttonSize() {
      if (!this.$q.platform.is.desktop) {
        return "6px";
      } else {
        return "9px";
      }
    },
    buttonText() {
      return this.$q.platform.is.desktop ? "Action" : "";
    },
  },

  mounted() { 
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}

.my-card {
  width: 100%;
  height: 100%;
  max-height: 220px;
  color: $grey-9;
}

.badge {
  background-color: transparent;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 2;
}

.banner {
  height: 70%;
  transition: all 300ms ease;
}

.category-title {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.category-title-small {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.course-title {
  font-size: min(2.5vw, 16px);
  font-family: Poppins, sans-serif;
}

.items {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
}

.item {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  min-height: 20px;
  max-width: 80px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.item-small {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  min-height: 30px;
  max-width: 50px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.upper-text {
  margin-bottom: 0;
  font-size: min(1.5vw, 15px);
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

.lower-text {
  margin-bottom: 0;
  font-size: min(1vw, 9px);
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

@media only screen and (min-width: 1024px) and (max-width: 1439.99px) {
  .my-card {
    height: 100%;
    width: 100%;
    max-height: 220px;
    min-width: 250px;
  }

  .item {
    background-color: $yellow-2;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 32px;
    min-height: 20px;
    max-width: 50px;
    margin-right: 10px;
  }

  .course-title {
    font-size: 12px;
  }

  .upper-text {
    font-size: min(1.5vw, 12px);
  }
}

@media only screen and (min-width: 0px) and (max-width: 599.99px) {
  .upper-text {
    font-size: min(2.5vw, 12px);
  }

  .lower-text {
    font-size: min(1.7vw, 9px);
  }

  .course-title {
    font-size: 13px;
  }
}
</style>
