<template>
  <div class="col-12 row justify-center">
    <div
      :class="
        [this.show ? 'col-md-12 full-width ' : 'col-md-6'] +
        ' col-xs-12 shadow-1 row justify-center col-6 q-mt-lg q-pa-md rounded-borders item section'
      "
      style="width: 50%"
    >
      <div class="row justify-start items-center full-width">
        <div class="col-xs-8">
          <q-toolbar class="text-blue-7" style="background-color: #ffffff">
            <q-toolbar-title class="text-h6 text-bold q-pa-sm">
              {{ title }}
            </q-toolbar-title>
          </q-toolbar>
        </div>
        <div class="col-xs-4">
          <div class="row justify-end items-end">
            <q-icon
              @click="this.show = !this.show"
              class="rounded-borders individual-bg cursor-pointer"
              :name="this.show ? 'expand_less' : 'expand_more'"
              color="blue-7"
              :size="iconSize"
            ></q-icon>
          </div>
        </div>
      </div>
      <div class="q-pa-sm" v-for="(sbwl, i) in getSbwlLength()" :key="i">
        <q-btn-dropdown
          v-if="this.show"
          color="blue-7"
          :label="
            this.study_id === 'bbe'
              ? `${$t('studyplan.sbwl_button_sbwl')}`
              : `${i + 1}. ${$t('studyplan.sbwl_button')}`
          "
          dropdown-icon="add_circle"
          :disable="disableButtonSelect(i)"
        >
          <q-item
            clickable
            v-close-popup
            v-for="(sbwl, j) in getFilteredSbwlsArray()"
            :key="j"
            @click="selectSbwl(sbwl, i)"
          >
            <q-item-section>
              <q-item-label
                >{{ sbwl.name }}
                <span v-if="sbwl.ects"> - {{ sbwl.ects }} Ects </span>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-btn-dropdown>
      </div>
      <div v-if="this.show" class="col-12 row justify-center q-pa-sm">
        <div class="col-12 row">
          <div
            class="col-12 row q-col-gutter-x-md"
            v-for="(sbwl, i) in this.sbwls"
            :key="i"
          >
            <div class="col-12 row q-mt-lg">
              <div class="col row inline">
                <div class="text-h5 text-blue-7">{{ sbwl.sbwl_name }}</div>
                <q-btn
                  class="q-ml-md"
                  round
                  color="red-5"
                  icon="close"
                  size="sm"
                  style="opacity: 0.8"
                  @click="removeSbwl(sbwl)"
                >
                  <q-tooltip> {{ $t("studyplan.sbwl_remove") }} </q-tooltip>
                </q-btn>
              </div>
            </div>

            <div v-for="(course, i) in sbwl.states" :key="i" :class="colSize">
              <SbwlSubject
                class="q-mt-md"
                style="min-height: 250px"
                :sbwl="sbwl"
                :course="course"
                :course_index="i"
                :studyObject="studyObject"
                @update-status-sbwl="updateStatusSbwl"
                @update-grade-sbwl="updateGradeSbwl"
              />
            </div>
            <div
              class="row items-center"
              v-if="sbwl.sbwl_name == 'Courses Abroad'"
            >
              <q-btn
                round
                icon="add"
                color="blue-4"
                text-color="white"
                @click="this.sbwlCourseAbroad = true"
                :disable="checkCourseAbroadEcts >= 20"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog wenn sbwls noch nicht available -->

    <q-dialog v-model="sbwlsInactive">
      <q-card style="width: 700px; max-width: 80vw">
        <q-card-section>
          <div class="text-h6 text-bold text-center text-blue-7">
            {{ $t("studyplan.sbwl_not_available_heading") }}
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-body1">
            {{ $t("studyplan.sbwl_not_available_info") }}
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">
            {{ $t("studyplan.sbwl_not_available_tip") }}
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="negative" v-close-popup>{{
            $t("common.close_button")
          }}</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!--Dialog for Courses Abroad -->

    <q-dialog v-model="sbwlCourseAbroad">
      <q-card style="width: 700px; max-width: 80vw">
        <q-card-section>
          <div class="text-h6 text-bold text-blue-7">
            Kurs {{ sbwlCourseAbroadTitel() }} hinzufügen
          </div>
        </q-card-section>

        <q-card-section class="row justify-start q-col-gutter-xl q-pt-none">
          <!-- ECTS -->
          <div class="col-6">
            <q-input
              filled
              v-model="ects"
              type="number"
              label="ECTS"
              hint="ECTS-Punkte"
            />
          </div>
        </q-card-section>
        <q-card-section class="row justify-start">
          <!-- GRADE -->
          <div class="q-pl-md">
            <q-btn-dropdown color="amber-5" :label="getGradelabel">
              <q-list>
                <q-item
                  v-model="selectedGrade"
                  v-for="grade in grades"
                  :key="grade"
                  clickable
                  v-close-popup
                  @click="selectedGrade = grade"
                >
                  <q-item-section>
                    <q-item-label>
                      {{ grade }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            color="negative"
            :label="$t('common.close_button')"
            v-close-popup
          />
          <q-btn
            flat
            color="positive"
            :label="$t('common.send_button')"
            v-close-popup
            @click="selectCourseAbroad"
            :disable="!checkHinzufügen"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { getChecker } from "@/store/study_logic";
import { ref } from "vue";
import SbwlSubject from "@/components/study/SbwlSubject.vue";
import { useUserStore } from "@/store/user.store";
import { useQuasar } from "quasar";
export default {
  components: {
    SbwlSubject,
  },
  props: {
    // @TODO: clean up props
    study_id: {
      type: String,
      required: true,
    },
    studyObject: {
      type: Object,
      required: true,
    },
    sbwlss: {
      type: Array,
      required: true,
    },
    statusUpdated: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    let studyId = props.study_id || window.location.pathname.split("/")[2];

    if (!studyId) {
      throw new Error("No study id provided");
    }
    const checker = getChecker(studyId);
    const userStore = useUserStore();
    const q = useQuasar();
    return {
      studyId,
      checker,
      userStore,
      q,
    };
  },

  data() {
    return {
      sbwlsInactive: ref(false),
      sbwlCourseAbroad: ref(false),
      title: "SBWLs",
      show: true,
      sbwls: ref({
        /*
        sbwl_name: String,
        states: [
          {
            status: String,
            grade: String,
          },
        ],
        */
      }),
      /**
       * Course Abroad
       */
      ects: ref(""),
      grades: ref(["1", "2", "3", "4", "Teilgenommen"]),
      selectedGrade: ref(""),
    };
  },
  computed: {
    iconSize() {
      return this.$q.platform.is.desktop ? "md" : "xs";
    },
    checkSbwlStatus() {
      const matchingStatus = [];
      const sbwls = this.studyObject.study.subjects.filter(
        (obj) =>
          obj.category == "Spezielle Betriebswirtschaftslehre" ||
          obj.category == "Specializations"
      );
      if (!sbwls) {
        return false;
      }
      sbwls.forEach((obj) => {
        const entry = this.studyObject.subjectStatusEntry.find(
          (el) => el.subject_id == obj.subject_id
        );
        if (entry.status == "can-do" || entry.status == "done") {
          matchingStatus.push(entry);
        }
      });
      return matchingStatus;
    },
    colSize() {
      if (this.$q.platform.is.desktop) {
        return "col-xs-12 col-md-3";
      } else if (this.$q.screen.lt.sm) {
        return "col-12";
      } else if (this.$q.screen.lt.md) {
        return "col-6";
      } else {
        return "col-xs-12 col-md-3";
      }
    },
    allSbwlsDone() {
      let doneEcts = 0;
      let firstSbwl = false;
      let secondSbwl = false;
      let thirdSbwl = false;
      if (this.studyId == "bbe") {
        if (this.sbwls.length == 0) {
          return { firstSbwl: false, secondSbwl: false, thirdSbwl: false };
        } else {
          // Iterate over each sbwl in this.sbwlss
          for (let sbwl of this.sbwlss) {
            // Iterate over each state in sbwl.states
            for (
              let stateIndex = 0;
              stateIndex < sbwl.states.length;
              stateIndex++
            ) {
              let ects = 0;
              // Set ects based on sbwl_name and stateIndex
              if (sbwl.sbwl_name === "Urban and Regional Economics") {
                ects = stateIndex === 0 ? 4 : stateIndex === 1 ? 8 : 4;
              } else if (sbwl.sbwl_name === "Business Mathematics") {
                ects = stateIndex === 0 ? 8 : 4;
              } else if (
                sbwl.sbwl_name ===
                "Ecological Economics - Economy, Climate Change and Sustainability"
              ) {
                ects = 5;
              } else if (sbwl.sbwl_name === "Economics Fields") {
                ects = stateIndex < 2 ? 8 : 4;
              } else if (
                sbwl.sbwl_name === "Topics in Economic and Social History"
              ) {
                ects = 10;
              } else if (
                sbwl.sbwl_name === "European and International Economic Law" ||
                sbwl.sbwl_name === "Mathematical Methods" ||
                sbwl.sbwl_name === "Philosophy: Logic and Ethics"
              ) {
                ects = 5;
              } else if (
                sbwl.sbwl_name ===
                  "Ethics for Management, Organizations, and Society" ||
                sbwl.sbwl_name === "Sustainability Reporting"
              ) {
                ects = stateIndex < 2 ? 6 : 4;
              } else {
                // Standard logic
                ects = sbwl.states.length === 5 ? 4 : stateIndex < 2 ? 6 : 4;
              }

              if (sbwl.states[stateIndex].status === "done") {
                doneEcts += ects;
              }
            }
          }

          if (doneEcts >= 40) {
            firstSbwl = true;
          }

          return { firstSbwl, secondSbwl, thirdSbwl };
        }
      } else {
        let doneEctsFirst = 0;
        let doneEctsSecond = 0;
        let doneEctsThird = 0;
        if (this.sbwls.length == 0) {
          return {firstSbwl: false, secondSbwl: false, thirdSbwl: false};
        } else if (this.sbwls.length == 1) {
          this.sbwls[0].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsFirst += course.ects
            }
          })
          if(doneEctsFirst >= 20) {
            firstSbwl = true
          } else {
            firstSbwl = false
          }          
        } else if (this.sbwls.length == 2) {
          this.sbwls[0].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsFirst += course.ects
            }
          })
          if(doneEctsFirst >= 20) {
            firstSbwl = true
          } else {
            firstSbwl = false
          } 
          this.sbwls[1].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsSecond += course.ects
            }
          })
          if(doneEctsSecond >= 20) {
            secondSbwl = true
          } else {
            secondSbwl = false
          } 
        } else if (this.sbwls.length == 3) {
          this.sbwls[0].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsFirst += course.ects
            }
          })
          if(doneEctsFirst >= 20) {
            firstSbwl = true
          } else {
            firstSbwl = false
          } 
          this.sbwls[1].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsSecond += course.ects
            }
          })
          if(doneEctsSecond >= 20) {
            secondSbwl = true
          } else {
            secondSbwl = false
          } 
          this.sbwls[2].states.forEach(course => {
            if (course.status == 'done') {
            doneEctsThird += course.ects
            }
          })
          if(doneEctsThird >= 20) {
            thirdSbwl = true
          } else {
            thirdSbwl = false
          }
        }
        return { firstSbwl, secondSbwl, thirdSbwl };
      }
    },
    getGradelabel() {
      return this.selectedGrade == ""
        ? "Grade"
        : "Grade:" + " " + this.selectedGrade;
    },
    checkHinzufügen() {
      if (this.ects && this.selectedGrade) {
        return true;
      } else {
        return false;
      }
    },
    checkCourseAbroadEcts() {
      let ects = 0
      let coursesAbroad = this.sbwls.find(item => item.sbwl_name == 'Courses Abroad');
      coursesAbroad.states.forEach(course => {
        if (course.status == 'done') {
          ects += course.ects
        }
      })
      return ects
    }
  },
  methods: {
    getSbwlLength() {
      const specialCategories = [
        "Spezielle Betriebswirtschaftslehre",
        "Specializations",
      ];

      return this.studyObject.study.subjects.filter((obj) => {
        return specialCategories.includes(obj.category);
      });
    },
    getFilteredSbwlsArray() {
      return this.studyObject.sbwls;
    },
    disableButtonSelect(i) {
      if (this.studyId == "bbe") {
        let totalEcts = 0;

        // Iterate over each sbwl in this.sbwlss
        for (let sbwl of this.sbwlss) {
          // Find the corresponding sbwl in this.studyObject.sbwls
          let correspondingSbwl = this.studyObject.sbwls.find(
            (s) => s.name === sbwl.sbwl_name
          );

          // If the corresponding sbwl is found, add its ects to the totalEcts
          if (correspondingSbwl) {
            totalEcts += correspondingSbwl.ects;
          }
        }

        // Check if the total ects is greater than or equal to 40
        return totalEcts >= 40;
      } else {
        // Original condition for other study_id values
        return this.sbwlss.length !== i;
      }
    },
    sbwlCourseAbroadTitel() {
      const coursesAbroad = this.sbwls.find(
        (item) => item.sbwl_name == "Courses Abroad"
      );
      if (coursesAbroad) {
      const count = coursesAbroad.states.length;
      return count + 1;
      } else {
        return 1
      }
    },
    selectSbwl(sbwl) {
      const isSbwlAlreadySelected = this.sbwlss.some(
        (selectedSbwl) => selectedSbwl.sbwl_name === sbwl.name
      );

      if (!isSbwlAlreadySelected && sbwl.name !== "Courses Abroad") {
        if (this.checkSbwlStatus.length > 0) {
          this.sbwlsInactive = false;

          let modifiedSbwl;
          if (
            sbwl.name == "Ethics for Management, Organizations, and Society" ||
            sbwl.name == "Sustainability Reporting"
          ) {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 6 },
                { grade: 0, status: "can-do", ects: 6 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
              ],
            };
          } else if (sbwl.name == "Urban and Regional Economics") {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 8 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
              ],
            };
          } else if (sbwl.name == "Business Mathematics") {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 8 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
              ],
            };
          } else if (
            sbwl.name ==
            "Ecological Economics - Economy, Climate Change and Sustainability"
          ) {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 5 },
                { grade: 0, status: "can-do", ects: 5 },
                { grade: 0, status: "can-do", ects: 5 },
                { grade: 0, status: "can-do", ects: 5 },
              ],
            };
          } else if (sbwl.name == "Economics Fields") {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 8 },
                { grade: 0, status: "can-do", ects: 8 },
                { grade: 0, status: "can-do", ects: 4 },
              ],
            };
          } else if (
            sbwl.name == "European and International Economic Law" ||
            sbwl.name == "Mathematical Methods" ||
            sbwl.name == "Philosophy: Logic and Ethics"
          ) {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 5 },
                { grade: 0, status: "can-do", ects: 5 },
              ],
            };
          } else if (sbwl.name == "Topics in Economic and Social History") {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [{ grade: 0, status: "can-do", ects: 10 }],
            };
          } else if (sbwl.name == "Courses Abroad") {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [{ grade: 0, status: "can-do", ects: 10 }],
            };
          } else {
            modifiedSbwl = {
              sbwl_name: sbwl.name,
              states: [
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
                { grade: 0, status: "can-do", ects: 4 },
              ],
            };
          }
          sbwl.active = true;
          this.sbwls.push(modifiedSbwl);
          this.$emit("update-sbwl", sbwl);
        } else {
          this.sbwlsInactive = true;
        }
      } else if (!isSbwlAlreadySelected && sbwl.name == "Courses Abroad") {
        if (this.checkSbwlStatus.length > 0) {
        this.sbwlsInactive = false
        this.sbwlCourseAbroad = true;
        } else {
          this.sbwlsInactive = true
        }
      } else {
        this.q.notify({
          message: "SBWL/Spezialisierung bereits ausgewählt",
          color: "negative",
          timeout: 2000,
        });
      }
    },
    selectCourseAbroad() {
      if (this.ects && this.selectedGrade) {
        let grade = parseInt(this.selectedGrade, 10);
        if (isNaN(grade)) {
          grade = 0; // Wenn selectedGrade keine Zahl ist, setze es auf 0
        }
        const course = {
          ects: parseInt(this.ects, 10),
          grade: grade,
          status: "done",
        };
        let sbwl = this.sbwls.find(
          (item) => item.sbwl_name == "Courses Abroad"
        );
        if (!sbwl) {
          sbwl = {
            _id: 3,
            name: "Courses Abroad",
            sbwl_name: "Courses Abroad",
            states: [],
          };
          sbwl.states.push(course);
          this.sbwls.push(sbwl);
          this.$emit("update-sbwl", sbwl);
        } else {
          sbwl.states.push(course);
          const courseIndex = sbwl.states.indexOf(course);
          this.$emit("update-status-sbwl", sbwl, courseIndex, course.status);
        }
      }
    },
    updateStatusSbwl(sbwl, idx, status) {
      this.$emit("update-status-sbwl", sbwl, idx, status);
    },
    updateGradeSbwl(sbwl, idx, grade) {
      this.$emit("update-grade-sbwl", sbwl, idx, grade);
    },
    removeSbwl(sbwl) {
      console.log(sbwl)
      const index = this.sbwls.findIndex(
        (item) => item.sbwl_name === sbwl.sbwl_name
      );
      if (index !== -1) {
        this.sbwls.splice(index, 1);
      }
      this.$emit("remove-sbwl", sbwl);
    },
  },
  watch: {
    statusUpdated: {
      async handler() {
        if (this.statusUpdated) {
          if (this.checkSbwlStatus.length === 0) {
            if (this.sbwls.length > 0) {
              for (let i = 0; i < this.sbwls.length; i++) {
                const sbwl = this.sbwls[i];
                // Verzögerung vor dem Auslösen des remove-sbwl-Ereignisses
                await new Promise((resolve) => setTimeout(resolve, 200));
                this.$emit("remove-sbwl", sbwl);
              }
              this.sbwls = [];
            } else {
              this.sbwls = [];
            }
          }
        }
      },
      deep: true,
    },
    allSbwlsDone: {
      handler(newVal) {
        this.$emit("allSbwlsDone", {
          firstSbwl: newVal.firstSbwl,
          secondSbwl: newVal.secondSbwl,
          thirdSbwl: newVal.thirdSbwl,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.sbwls = this.sbwlss;
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}
</style>
